var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "message-box dialog-mask",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.onClickOutSide.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-box" }, [
        _vm._m(0),
        _vm._m(1),
        _c("div", { staticClass: "section button-area" }, [
          _c(
            "button",
            {
              staticClass: "button cancel",
              on: { click: _vm.onNegativeClick },
            },
            [_vm._v(_vm._s(_vm.negative))]
          ),
          _c(
            "button",
            {
              staticClass: "button danger",
              on: { click: _vm.onPositiveClick },
            },
            [_vm._v(_vm._s(_vm.positive))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "section dialog-header" }, [
      _c("h1", [_vm._v("プロット削除の最終確認")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "section dialog-body" }, [
      _c("div", [_vm._v("このプロットを削除してもよろしいですか？")]),
      _c("div", [
        _vm._v(
          " プロットを削除するとこのプロットと連携している時系列などのデータも削除されます。一度削除したデータは元には戻せません。 "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "plots" }, [
    _c("div", { staticClass: "head" }, [
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.plotName))]),
      _c(
        "div",
        { staticClass: "header-content" },
        [
          !_vm.isViewedByExternalUser
            ? _c("hamburger-menu", {
                staticClass: "hamburger-menu",
                attrs: {
                  items: _vm.hamburgerMenuItems,
                  styleObject: { backgroundColor: "#EDEDED" },
                  position: "bottom",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
    _c(
      "div",
      {
        staticClass: "plot-list",
        class: { flexColumn: _vm.displayFormat === "vertical" },
      },
      [
        _vm._l(_vm.plot.plotGroups, function (plotGroup, index) {
          return _c(
            "div",
            {
              key: `plot-order-${index}`,
              staticClass: "group",
              class: { vertical: _vm.displayFormat === "vertical" },
            },
            [
              _c("div", { staticClass: "plot-title" }, [
                _c("div", { staticClass: "plot-title-theme ellipsis" }, [
                  _c("span", { staticClass: "ellipsis" }, [
                    _vm._v(" " + _vm._s(plotGroup.name) + " "),
                  ]),
                  _c("p", { staticClass: "ellipsis pre-line" }, [
                    _vm._v(_vm._s(plotGroup.description)),
                  ]),
                ]),
              ]),
              _vm._l(plotGroup.plots, function (plot) {
                return _c(
                  "div",
                  { key: plot.key, staticClass: "plot-item-container" },
                  [
                    _c("div", { staticClass: "plot-item" }, [
                      _c("div", { staticClass: "text-box" }, [
                        _c("div", { staticClass: "text break-word" }, [
                          _vm._v(_vm._s(plot.text)),
                        ]),
                        _vm.isPC &&
                        plot.linkedPlotKey &&
                        (_vm.displayFormat === "vertical" || _vm.isMulti) &&
                        _vm.childPlotName(plot.linkedPlotKey)
                          ? _c("div", { staticClass: "link-child" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "child-plot-link",
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickChildPlot(
                                        plot.linkedPlotKey
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("link-icon", {
                                    staticClass: "icon",
                                    attrs: { size: 16 },
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      "子プロット：" +
                                        _vm._s(
                                          _vm.childPlotName(plot.linkedPlotKey)
                                        )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "circle" }),
                    ]),
                  ]
                )
              }),
            ],
            2
          )
        }),
        !_vm.isViewedByExternalUser
          ? _c(
              "button-round",
              {
                attrs: {
                  to: {
                    name: "plotEdit",
                    params: { novelId: _vm.novelId, plotId: _vm.plotId },
                  },
                },
              },
              [_vm._v(" 編集する ")]
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onClickOutside,
          expression: "onClickOutside",
        },
      ],
      staticClass: "hamburger-menu line-height-0",
    },
    [
      _c("div", { staticClass: "main", on: { click: _vm.onClickMenu } }, [
        _c(
          "button",
          {
            staticClass: "hamburger hamburger--spin",
            class: { "is-active": _vm.isOpen },
            attrs: { type: "button" },
          },
          [_vm._m(0)]
        ),
      ]),
      _vm.isOpen
        ? _c(
            "div",
            {
              staticClass: "menu",
              class: { bottom: _vm.position === "bottom" },
            },
            [
              _vm._l(_vm.items, function (item, index) {
                return [
                  _c(
                    "div",
                    {
                      key: index,
                      staticClass: "item gap-1/2 align-items-center",
                      style: _vm.styleObject,
                      on: {
                        click: function ($event) {
                          return _vm.onClickItem(item)
                        },
                      },
                    },
                    [
                      item.icon === "export-variant"
                        ? _c("export-variant-icon", {
                            staticClass: "flex flex-center",
                            attrs: { fillColor: _vm.fillColor(item.color) },
                          })
                        : _vm._e(),
                      item.icon === "tray-arrow-down"
                        ? _c("tray-arrow-down-icon", {
                            staticClass: "flex flex-center",
                            attrs: { fillColor: _vm.fillColor(item.color) },
                          })
                        : _vm._e(),
                      item.icon === "cog"
                        ? _c("cog-icon", {
                            staticClass: "flex flex-center",
                            attrs: { fillColor: _vm.fillColor(item.color) },
                          })
                        : _vm._e(),
                      item.icon === "note-text-outline"
                        ? _c("note-text-outline-icon", {
                            staticClass: "flex flex-center",
                            attrs: { fillColor: _vm.fillColor(item.color) },
                          })
                        : _vm._e(),
                      item.icon === "checkbox-marked-outline"
                        ? _c("checkbox-marked-outline-icon", {
                            staticClass: "flex flex-center",
                            attrs: { fillColor: _vm.fillColor(item.color) },
                          })
                        : _vm._e(),
                      item.icon === "palette"
                        ? _c("palette-icon", {
                            staticClass: "flex flex-center",
                            attrs: { fillColor: _vm.fillColor(item.color) },
                          })
                        : _vm._e(),
                      item.icon === "swap"
                        ? _c("swap-horizontal-icon", {
                            staticClass: "flex flex-center",
                            attrs: { fillColor: _vm.fillColor(item.color) },
                          })
                        : _vm._e(),
                      item.icon === "delete"
                        ? _c("delete-icon", {
                            staticClass: "flex flex-center",
                            attrs: { fillColor: _vm.fillColor(item.color) },
                          })
                        : _vm._e(),
                      item.icon === "history"
                        ? _c("history-icon", {
                            staticClass: "flex flex-center",
                            attrs: { fillColor: _vm.fillColor(item.color) },
                          })
                        : _vm._e(),
                      item.icon === "shared-novel"
                        ? _c("link-icon", {
                            staticClass: "flex flex-center",
                            attrs: { fillColor: _vm.fillColor(item.color) },
                          })
                        : _vm._e(),
                      _c(
                        "span",
                        { staticClass: "flex-1", style: item.fontStyle },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      ),
                    ],
                    1
                  ),
                ]
              }),
              _vm._l(_vm.togglableItems, function (item) {
                return [
                  _c(
                    "div",
                    {
                      key: item.name,
                      staticClass: "item gap-1/2 align-items-center",
                      style: _vm.styleObject,
                    },
                    [
                      item.icon === "content-save-outline"
                        ? _c("content-save-outline-icon", {
                            staticClass: "flex flex-center",
                            attrs: { fillColor: _vm.fillColor(item.color) },
                          })
                        : _vm._e(),
                      item.icon === "toolbox-outline"
                        ? _c("toolbox-outline-icon", {
                            staticClass: "flex flex-center",
                            attrs: { fillColor: _vm.fillColor(item.color) },
                          })
                        : _vm._e(),
                      item.icon === "format-indent-increase"
                        ? _c("format-indent-increase-icon", {
                            staticClass: "flex flex-center",
                            attrs: { fillColor: _vm.fillColor(item.color) },
                          })
                        : _vm._e(),
                      _c(
                        "span",
                        { staticClass: "flex-1", style: item.fontStyle },
                        [_vm._v(_vm._s(item.name))]
                      ),
                      _c("toggle-button", {
                        attrs: { value: item.value, sync: true, labels: true },
                        on: {
                          change: function ($event) {
                            return _vm.onChangeState(item.callback)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "hamburger-box" }, [
      _c("span", { staticClass: "hamburger-inner" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }